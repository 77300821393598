import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/material.css";
import Switch from "@mui/material/Switch";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useRecoilState } from "recoil";
import { userInfoState } from "../global-state";
import LoadingSpinner from "../common/loading";
import ErrorIcon from "@mui/icons-material/Error";
import {
  disableAutomation,
  enableAutomation,
  listAutomations,
  batchDelete,
} from "./automation-service";
import { useTheme } from "@mui/material/styles";
import { useToast } from "../common/toast";
import useApiCall from "../common/api-call";
import dayjs from "dayjs";
import { customFormat } from "../common/custom-format";
import AutomationFilter from "./automation-filter";
import MSwitch from "../common/switch";
import DeleteIcon from "@mui/icons-material/Delete";

const AutomationListView = ({ showFilter = false, setShowFilter }) => {
  const history = useHistory();
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const { successToast, errorToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const [automations, setAutomations] = useState([]);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [deleteAutomationId, setDeleteAutomationId] = useState(null);
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [sortBy, setSortBy] = useState("alphabet");
  const [filterText, setFilterText] = useState("");
  const [isShowFilter, setIsShowFilter] = useState(showFilter);
  const [selectedTags, setSelectedTags] = useState(["all"]);
  const [tags, setTags] = useState(["all"]);
  const [isMultiSelectMode, setIsMultiSelectMode] = useState(false);
  const [selectedRecurrings, setSelectedRecurrings] = useState([]);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [isRunning, setIsRunning] = useState("");

  useEffect(() => {
    setIsShowFilter(showFilter);
  }, [showFilter]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(dayjs());
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    handleListAutomations();
  }, []);

  useEffect(() => {
    const savedFilterParams = localStorage.getItem("filterParams-recurring");
    if (savedFilterParams) {
      setShowFilter(true);
      const parsedParams = JSON.parse(savedFilterParams);
      if (parsedParams.filter) {
        setFilterText(parsedParams.filter);
      }
      if (parsedParams.sort) {
        setSortBy(parsedParams.sort);
      }

      if (parsedParams.tags) {
        const tagsArray = parsedParams.tags.split(",");
        setTags(
          tagsArray.length > 0 && tagsArray[0] !== "" ? tagsArray : ["all"]
        );
      }
      updateURL(parsedParams);
    } else {
      setFilterText("");
      setSortBy("alphabet");
      setSelectedTags(["all"]);
      updateURL("");
    }
  }, [showFilter]);

  const updateURL = (updates) => {
    if (!updates) {
      window.history.replaceState(null, "", "/automation/list");
      return;
    }
    const currentParams = new URLSearchParams(location.search);

    Object.entries(updates).forEach(([key, value]) => {
      if (
        value &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0)
      ) {
        currentParams.set(key, value);
      } else {
        currentParams.delete(key);
      }
    });

    const newURL = `${location.pathname}?${currentParams.toString()}`;
    window.history.replaceState(null, "", newURL);
  };

  const sortedAutomations =
    automations
      .filter((a) => a.name.toLowerCase().includes(filterText.toLowerCase()))
      .sort((a, b) => {
        if (sortBy === "time") {
          const aTimestamp = a.timestamp
            ? dayjs(a.timestamp).valueOf()
            : Number.NEGATIVE_INFINITY;
          const bTimestamp = b.timestamp
            ? dayjs(b.timestamp).valueOf()
            : Number.NEGATIVE_INFINITY;
          return bTimestamp - aTimestamp;
        } else if (sortBy === "alphabet") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          const aIsLetter = /^[a-zA-Z]/.test(aName);
          const bIsLetter = /^[a-zA-Z]/.test(bName);
          if (aIsLetter && !bIsLetter) return -1;
          if (!aIsLetter && bIsLetter) return 1;
          return aName.localeCompare(bName);
        } else if (sortBy === "opp-time") {
          const aTimestamp = a.timestamp
            ? dayjs(a.timestamp).valueOf()
            : Number.POSITIVE_INFINITY;
          const bTimestamp = b.timestamp
            ? dayjs(b.timestamp).valueOf()
            : Number.POSITIVE_INFINITY;
          return aTimestamp - bTimestamp;
        } else if (sortBy === "opp-alphabet") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          const aIsLetter = /^[a-zA-Z]/.test(aName);
          const bIsLetter = /^[a-zA-Z]/.test(bName);
          if (aIsLetter && !bIsLetter) return 1;
          if (!aIsLetter && bIsLetter) return -1;
          return bName.localeCompare(aName);
        } else if (sortBy === "count") {
          const countA = a.totalCount ?? -1;
          const countB = b.totalCount ?? -1;

          return countB - countA;
        }
      }) || [];

  const handleListAutomations = async () => {
    setLoading(true);
    try {
      const resp = await listAutomations(
        apiCall,
        userInfo.activeScope.id,
        "standard",
        "recurring"
      );
      if (resp && Array.isArray(resp.automations)) {
        const recurringAutomations = resp["automations"].filter(
          (automation) => automation.type === "RECURRING"
        );
        const allTags = recurringAutomations.reduce(
          (acc, automation) => {
            if (Array.isArray(automation.tags) && automation.tags.length > 0) {
              automation.tags.forEach((tag) => {
                if (!acc.includes(tag)) {
                  acc.push(tag);
                }
              });
            }
            return acc;
          },
          ["all"]
        );

        setTags(allTags);

        setAutomations(recurringAutomations);
        setLoading(false);
      } else {
        setAutomations([]);
      }
    } catch (error) {
      console.error("Failed to fetch automations:", error);
      setAutomations([]);
    } finally {
      setLoading(false);
    }
  };

  const openOverview = (data) => {
    history.push(`/automation/overview?id=${data.id}`);
  };

  const handleEnableDisable = async (event, enableDisable, automationId) => {
    if (enableDisable) {
      const success = await enableAutomation(
        apiCall,
        userInfo.activeScope.id,
        automationId
      );
      if (success) {
        successToast("Enabled.");
        const na = [...automations];
        na.filter((a) => a.id === automationId).forEach(
          (a) => (a.enabled = enableDisable)
        );
        setAutomations(na);
      } else {
        errorToast("Error to enable the automation.");
      }
    } else {
      const success = await disableAutomation(
        apiCall,
        userInfo.activeScope.id,
        automationId
      );
      if (success) {
        successToast("Disabled.");
        const na = [...automations];
        na.filter((a) => a.id === automationId).forEach(
          (a) => (a.enabled = enableDisable)
        );
        setAutomations(na);
      } else {
        errorToast("Error to disable the automation.");
      }
    }
  };

  const handleTagsChange = (tags) => {
    setSelectedTags(tags);
  };

  let pressTimer;
  const handleMouseDown = (automationId) => {
    pressTimer = setTimeout(() => handleLongPress(automationId), 800);
  };

  const handleMouseUp = () => {
    clearTimeout(pressTimer);
  };

  const handleCheckboxChange = (e, automationId) => {
    if (e.target.checked) {
      setSelectedRecurrings((prev) => [...prev, automationId]);
    } else {
      setSelectedRecurrings((prev) => prev.filter((id) => id !== automationId));
    }
  };

  const handleSelectAll = () => {
    const totalAutomations = automations.length;
    const selectedCount = selectedRecurrings.length;

    if (selectedCount === 0) {
      setSelectedRecurrings(automations.map((automation) => automation.id));
    } else if (selectedCount === totalAutomations) {
      setSelectedRecurrings([]);
    } else {
      const newSelectedRecurrings = [...selectedRecurrings];
      newSelectedRecurrings.pop();
      setSelectedRecurrings(newSelectedRecurrings);
    }
  };

  const handleConfirmDelete = () => {
    setDeleteConfirmOpen(true);
  };

  const handleDeleteSelected = async () => {
    setIsRunning("DELETE");
    try {
      const resp = await batchDelete(
        apiCall,
        userInfo.activeScope.id,
        selectedRecurrings,
        false
      );
      console.log(selectedRecurrings);
      if (resp) {
        successToast("Success!");
      }
    } catch (error) {
      errorToast("Error!");
    } finally {
      handleListAutomations();
      setIsMultiSelectMode(false);
      setSelectedRecurrings([]);
      setIsRunning("");
    }
  };

  const handleCancelMultiSelect = () => {
    setIsMultiSelectMode(false);
    setSelectedRecurrings([]);
  };

  const handleLongPress = (automationId) => {
    setIsMultiSelectMode(true);
    setSelectedRecurrings([automationId]);
  };

  const handlePressStart = (automationId) => {
    pressTimer = setTimeout(() => handleLongPress(automationId), 800);
  };

  const handlePressEnd = () => {
    clearTimeout(pressTimer);
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Box sx={{ flexGrow: 1, m: 2, pb: 12 }}>
          {isShowFilter && (
            <AutomationFilter
              sortBy={sortBy}
              setSortBy={setSortBy}
              setFilterText={setFilterText}
              setShowFilter={setShowFilter}
              onTagsChange={handleTagsChange}
              tags={tags}
              type="recurring"
            />
          )}
          {isMultiSelectMode && (
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", p: 2, gap: 1 }}
            >
              <Checkbox
                checked={
                  selectedRecurrings.length === automations.length &&
                  automations.length > 0
                }
                indeterminate={
                  selectedRecurrings.length > 0 &&
                  selectedRecurrings.length < automations.length
                }
                onChange={handleSelectAll}
              />
              <IconButton
                color="error"
                onClick={handleConfirmDelete}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
              <Button variant="outlined" onClick={handleCancelMultiSelect}>
                Cancel
              </Button>
            </Box>
          )}

          {automations.length !== 0 ? (
            <Grid
              container
              spacing={{ xs: 1, md: 2 }}
              columns={{ xs: 2, sm: 4, md: 12 }}
            >
              {sortedAutomations
                .filter((automation) => {
                  if (selectedTags.includes("all")) return true;
                  return (
                    automation.tags.filter((x) => selectedTags.includes(x))
                      .length === selectedTags.length
                  );
                })
                .map((automation, index) => (
                  <Grid item xs={2} sm={2} md={4} key={index}>
                    <Card
                      elevation={0}
                      onMouseDown={() => handleMouseDown(automation.id)}
                      onMouseUp={handleMouseUp}
                      onTouchStart={() => handlePressStart(automation.id)}
                      onTouchEnd={handlePressEnd}
                    >
                      <CardActionArea
                        onClick={() => {
                          if (!isMultiSelectMode) {
                            openOverview(automation);
                          }
                        }}
                        style={{
                          backgroundColor: theme.palette.background.card,
                        }}
                      >
                        {isMultiSelectMode && (
                          <Checkbox
                            checked={selectedRecurrings.includes(automation.id)}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleCheckboxChange(e, automation.id);
                            }}
                          />
                        )}
                        <CardContent sx={{ padding: "8px" }}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Typography
                              gutterBottom
                              variant="body1"
                              component="div"
                              sx={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 3,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxHeight: "4.5em",
                                marginBottom: "0px",
                              }}
                            >
                              {automation.name}
                            </Typography>
                            {!automation.runnable ? (
                              <ErrorIcon
                                style={{
                                  color: "red",
                                  marginRight: "15px",
                                  marginLeft: "15px",
                                }}
                              />
                            ) : (
                              <MSwitch
                                checked={automation.enabled}
                                disabled={!automation.runnable}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e, b) =>
                                  handleEnableDisable(e, b, automation.id)
                                }
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            )}
                          </Box>
                          {automation.timestamp !== 0 && (
                            <Box>
                              <Typography
                                variant="caption"
                                component="div"
                                sx={{
                                  display: "-webkit-box",
                                  WebkitBoxOrient: "vertical",
                                  WebkitLineClamp: 3,
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  marginTop: "5px",
                                  color: theme.palette.text.gray,
                                }}
                              >
                                {customFormat(
                                  dayjs(automation.timestamp),
                                  currentTime
                                )}
                              </Typography>
                            </Box>
                          )}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography variant="h6" color="textSecondary" sx={{ mb: 1 }}>
                No recurring automations
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  whiteSpace: "pre-line",
                  width: "100%",
                }}
              >
                {`Use + button above to create new one`}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{
                  whiteSpace: "pre-line",
                  width: "100%",
                }}
              >
                {`Or ask the chatbot`}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this recurring automation? This
            action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button
            onClick={handleDeleteSelected}
            color="error"
            variant="contained"
            disabled={isRunning === "DELETE"}
          >
            Delete
            {isRunning === "DELETE" && (
              <CircularProgress size={16} sx={{ ml: 1 }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AutomationListView;

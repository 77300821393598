export const upsertWidget = async (apiCall, scopeId, canvasId, uiWidgets) => {
  const request = { scopeId, canvasId, uiWidgets };
  console.log(request);
  const response = await apiCall("/ui/widget/save", "POST", request);
  return response && response.success;
};

export const updateCanvasCards = async (apiCall, scopeId, canvasId, cards) => {
  const request = { scopeId, canvasId, cards };
  const response = await apiCall("/ui/card/upsert", "POST", request);
  return response && response.success;
};

export const updateCanvasLayout = async (apiCall, scopeId, canvasId, cards) => {
  const request = { scopeId, canvasId, cards };
  const response = await apiCall("/ui/card/layout/update", "POST", request);
  return response && response.success;
};

export const removeCanvasCard = async (apiCall, scopeId, canvasId, cardId) => {
  const request = { scopeId, canvasId, cardId };
  const response = await apiCall("/ui/card/remove", "POST", request);
  return response;
};

export const deleteCard = async (apiCall, scopeId, cardId) => {
  const request = { scopeId, cardId };
  const response = await apiCall("/ui/card/delete", "POST", request);
  return response;
};

export const updateWidgetData = async (sendAndWaitWs, uiWidget) => {
  try {
    const resp = await sendAndWaitWs(
      "/ui/widget/update",
      JSON.stringify({
        uiWidget,
      })
    );
    if (resp.error) {
      console.log(resp.error);
      return null;
    }
    return resp;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const triggerWidgetEvent = async (sendAndWaitWs, widgetId) => {
  try {
    const resp = await sendAndWaitWs(
      "/ui/widget/trigger",
      JSON.stringify({
        widgetId,
      })
    );
    if (resp.error) {
      console.log(resp.error);
      return null;
    }
    return resp;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import { useToast } from "../common/toast";

const EditCardDialog = ({
  open,
  card,
  onEditWidgets,
  onClose,
  onSave,
  onRemove,
}) => {
  const [cardId, setCardId] = useState("");
  const [name, setName] = useState("");
  const [widgets, setWidgets] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [selectedWidgetId, setSelectedWidgetId] = useState(null);
  const { successToast, warningToast } = useToast();

  const theme = useTheme();

  useEffect(() => {
    if (card) {
      setName(card.name);
      setCardId(card.id);
      setWidgets(card.widgets);
    }
  }, [card]);

  const handleNameChange = (e) => {
    setName(e.target.value);
    setNameError(false);
  };

  const handleJoinWidget = () => {
    if (selectedWidgetId) {
      const widgetExists = widgets.some(
        (widget) => widget.id === selectedWidgetId
      );
      if (!widgetExists) {
        const selectedWidget = {
          id: selectedWidgetId,
          name: `Widget ${selectedWidgetId}`,
        }; // Dummy widget name
        setWidgets([...widgets, selectedWidget]);
        setSelectedWidgetId(null);
      } else {
        warningToast(`Widget is already in the card.`);
      }
    }
  };

  const handleRemoveWidget = (id) => {
    setWidgets(widgets.filter((widget) => widget.id !== id));
  };

  const handleEditWidget = (widgetId) => {
    onEditWidgets([widgetId]);
  };

  const handleRemove = () => {
    onRemove(cardId);
  };

  const handleSave = () => {
    if (!name.trim()) {
      setNameError(true);
      return;
    }
    const updatedCard = {
      ...card,
      name,
      widgets,
    };
    onSave(updatedCard);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Card</DialogTitle>
      {/*<DialogContent dividers>*/}
      {/*  <TextField*/}
      {/*    autoFocus*/}
      {/*    margin="dense"*/}
      {/*    id="Name"*/}
      {/*    label="Card Name"*/}
      {/*    type="text"*/}
      {/*    fullWidth*/}
      {/*    value={name}*/}
      {/*    onChange={handleNameChange}*/}
      {/*    error={nameError}*/}
      {/*    helperText={nameError && "Name cannot be empty"}*/}
      {/*  />*/}
      {/*  <SearchableSelectInput*/}
      {/*    valueSpec={{ enums: ["Widget A", "Widget B"], ids: ["1", "2"] }}*/}
      {/*    onChange={(newName, newWidgetId) => setSelectedWidgetId(newWidgetId)}*/}
      {/*    onError={() => {}}*/}
      {/*    multiSelect={false}*/}
      {/*  />*/}
      {/*  <IconButton onClick={handleJoinWidget} color="primary">*/}
      {/*    <JoinInnerIcon />*/}
      {/*  </IconButton>*/}

      {/*  <List style={{ maxHeight: "600px", overflowY: "auto" }}>*/}
      {/*    {widgets.map((widget) => (*/}
      {/*      <ListItem key={widget.id}>*/}
      {/*        <ListItemText primary={widget.name} />*/}
      {/*        <ListItemSecondaryAction>*/}
      {/*          <IconButton*/}
      {/*            edge="end"*/}
      {/*            onClick={() => handleEditWidget(widget.id)}*/}
      {/*          >*/}
      {/*            <EditIcon />*/}
      {/*          </IconButton>*/}
      {/*          <IconButton*/}
      {/*            edge="end"*/}
      {/*            onClick={() => handleRemoveWidget(widget.id)}*/}
      {/*          >*/}
      {/*            <DeleteIcon />*/}
      {/*          </IconButton>*/}
      {/*        </ListItemSecondaryAction>*/}
      {/*      </ListItem>*/}
      {/*    ))}*/}
      {/*  </List>*/}
      {/*</DialogContent>*/}
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleRemove}
          sx={{
            color: theme.palette.text.invalid,
          }}
        >
          Remove
        </Button>
        {/*<Button onClick={handleSave} color="primary">*/}
        {/*  Save*/}
        {/*</Button>*/}
      </DialogActions>
    </Dialog>
  );
};

export default EditCardDialog;

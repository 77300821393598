import React, { useState, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Typography,
  Box,
  Alert,
  CircularProgress,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";

const EditGroupDialog = ({
  open,
  onClose,
  onSave,
  onDelete, // Add onDelete prop
  groupName,
  setGroupName,
  entities,
  selectedEntities,
  onToggleEntity,
  isNewGroup = true, // Add a flag to determine if it's a new group
}) => {
  const theme = useTheme();
  const [filterText, setFilterText] = useState("");
  const [nameError, setNameError] = useState(false);
  const [showSelectedOnly, setShowSelectedOnly] = useState(false);
  const [isRunning, setIsRunning] = useState("");
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  // Get unavailable entities and their count
  const unavailableEntities = useMemo(() => {
    const availableIds = entities.map(
      (entity) => `${entity.integration}-${entity.id}`
    );
    return selectedEntities.filter((id) => !availableIds.includes(id));
  }, [entities, selectedEntities]);

  const unavailableCount = unavailableEntities.length;

  const handleClose = () => {
    setFilterText("");
    setNameError(false);
    onClose();
  };

  const handleSave = async () => {
    setIsRunning("SAVE");
    if (!groupName.trim()) {
      setNameError(true);
      setIsRunning("");
      return;
    }

    // Uncheck all unavailable entities before saving
    const updatedSelection = selectedEntities.filter((entityId) => {
      const availableIds = entities.map(
        (entity) => `${entity.integration}-${entity.id}`
      );
      return availableIds.includes(entityId);
    });

    setFilterText("");
    setNameError(false);

    try {
      await onSave(updatedSelection);
    } catch (error) {
      console.error("Save failed:", error);
    } finally {
      setIsRunning("");
    }
  };

  const handleNameChange = (e) => {
    setGroupName(e.target.value);
    if (nameError) {
      setNameError(false);
    }
  };

  const handleDelete = () => {
    setDeleteConfirmOpen(true);
  };

  const confirmDelete = async () => {
    setIsRunning("DELETE");
    if (onDelete) {
      try {
        await onDelete();
      } catch (error) {
        console.error("Delete failed:", error);
      } finally {
        setDeleteConfirmOpen(false);
        setIsRunning("");
      }
    }
  };

  const filteredEntities = entities.filter((entity) => {
    const matchesFilter = entity.name
      .toLowerCase()
      .includes(filterText.toLowerCase());
    const isSelected = selectedEntities.includes(
      `${entity.integration}-${entity.id}`
    );
    return showSelectedOnly ? isSelected && matchesFilter : matchesFilter;
  });

  const handleToggleAll = () => {
    const allEntityIds = entities.map(
      (entity) => `${entity.integration}-${entity.id}`
    );

    if (selectedEntities.length === 0) {
      allEntityIds.forEach((id) => {
        if (!selectedEntities.includes(id)) {
          onToggleEntity(id);
        }
      });
    } else if (selectedEntities.length === entities.length) {
      allEntityIds.forEach((id) => {
        if (selectedEntities.includes(id)) {
          onToggleEntity(id);
        }
      });
    } else {
      const entityToRemove = selectedEntities[selectedEntities.length - 1];
      onToggleEntity(entityToRemove);
    }
  };

  const isIndeterminate =
    selectedEntities.length > 0 && selectedEntities.length < entities.length;
  const isChecked = selectedEntities.length === entities.length;

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{isNewGroup ? "Create Group" : "Edit Group"}</DialogTitle>
        <DialogContent
          sx={{
            overflow: "visible",
          }}
        >
          <TextField
            fullWidth
            label="Group Name"
            value={groupName}
            variant="outlined"
            onChange={handleNameChange}
            error={nameError}
            helperText={nameError ? "Group name is required" : ""}
            sx={{
              mb: 2,
            }}
          />

          {unavailableCount > 0 && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              {unavailableCount}{" "}
              {unavailableCount === 1 ? "entity is" : "entities are"} no longer
              available. They will be automatically removed upon saving.
            </Alert>
          )}
          <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search entities..."
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={showSelectedOnly}
              onChange={(e) => setShowSelectedOnly(e.target.checked)}
            />
            <Typography style={{ marginLeft: "8px" }}>Show Selected</Typography>
          </Box>

          <Box display="flex" alignItems="center" mb={1}>
            <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
              Entities
            </Typography>
          </Box>

          <Box sx={{ padding: "8px 16px" }}>
            <Checkbox
              edge="start"
              indeterminate={isIndeterminate}
              checked={isChecked}
              onChange={handleToggleAll}
            />
          </Box>

          <List sx={{ maxHeight: 300, overflow: "auto" }}>
            {filteredEntities.map((entity) => (
              <ListItem key={`${entity.integration}-${entity.id}`}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedEntities.includes(
                      `${entity.integration}-${entity.id}`
                    )}
                    onChange={() =>
                      onToggleEntity(`${entity.integration}-${entity.id}`)
                    }
                  />
                </ListItemIcon>
                <ListItemText primary={entity.name} />
              </ListItem>
            ))}
            {filteredEntities.length === 0 && (
              <ListItem>
                <ListItemText
                  primary="No matching entities found"
                  sx={{ textAlign: "center", color: "text.secondary" }}
                />
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions sx={{ mt: -2 }}>
          {!isNewGroup && onDelete && (
            <Button
              onClick={handleDelete}
              color="error"
              disabled={isRunning !== ""}
              sx={{ mr: "auto" }}
            >
              Delete Group
            </Button>
          )}
          <Button onClick={handleClose} disabled={isRunning !== ""}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            disabled={!groupName.trim() || isRunning !== ""}
          >
            Save
            {isRunning === "SAVE" && (
              <CircularProgress size={16} sx={{ ml: 1 }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this group? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button
            onClick={confirmDelete}
            color="error"
            variant="contained"
            disabled={isRunning === "DELETE"}
          >
            Delete
            {isRunning === "DELETE" && (
              <CircularProgress size={16} sx={{ ml: 1 }} />
            )}
          </Button>
        </DialogActions>
      </Dialog>{" "}
    </>
  );
};

export default EditGroupDialog;

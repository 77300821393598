import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";

const UpsertWidgetDialog = ({
  open,
  titleWidget,
  currentWidget,
  existingNames,
  onClose,
  onSave,
}) => {
  const [dupNames, setDupNames] = useState([]);
  const [name, setName] = useState("");
  const [widgetType, setWidgetType] = useState("");
  const [unit, setUnit] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [options, setOptions] = useState([]);
  const [optionInput, setOptionInput] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [isInteger, setIsInteger] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [unitError, setUnitError] = useState(false);
  const [widgetTypeError, setWidgetTypeError] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (titleWidget) {
      setName(titleWidget.name);
    }
    if (currentWidget) {
      setReadOnly(currentWidget.disabled || false);
      setWidgetType(currentWidget.data.type);
      setUnit(currentWidget.data.unit || "");
      if (currentWidget.data.min !== null) {
        setMin(currentWidget.data.min);
      }
      if (currentWidget.data.max !== null) {
        setMax(currentWidget.data.max);
      }
      setOptions(currentWidget.data.options ? currentWidget.data.options : []);
      setButtonName(currentWidget.data.label || "");
      setIsInteger(currentWidget.data.integer || false);
    }
    return () => {};
  }, [currentWidget]);

  useEffect(() => {
    const names = [...existingNames];
    if (titleWidget) {
      const index = names.indexOf(titleWidget.name);
      if (index > -1) {
        names.splice(index, 1);
      }
    }
    setDupNames(names);
    return () => {};
  }, [titleWidget, existingNames]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleButtonNameChange = (event) => {
    setButtonName(event.target.value);
  };

  const handleWidgetTypeChange = (event) => {
    setWidgetType(event.target.value);
  };

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleMinChange = (event) => {
    setMin(event.target.value);
  };

  const handleMaxChange = (event) => {
    setMax(event.target.value);
  };

  const handleOptionInputChange = (event) => {
    setOptionInput(event.target.value);
  };

  const handleOptionInputKeyDown = (event) => {
    if (event.key === "Enter" && optionInput.trim() !== "") {
      addOption();
    }
  };

  const handleDeleteOption = (optionToDelete) => () => {
    setOptions(options.filter((option) => option !== optionToDelete));
  };

  const addOption = () => {
    if (optionInput.trim() !== "") {
      setOptions([...options, optionInput.trim()]);
      setOptionInput("");
    }
  };

  const handleReadOnlyChange = (event) => {
    setReadOnly(event.target.checked);
  };

  const handleIsIntegerChange = (event) => {
    setIsInteger(event.target.checked);
  };

  const handleSave = () => {
    if (name.trim() === "") {
      setNameError(true);
      return;
    }
    if (dupNames.indexOf(name.trim()) !== -1) {
      setNameError(true);
      return;
    }
    if (widgetType.trim() === "") {
      setWidgetTypeError(true);
      return;
    }

    const widgetTitle = { title: name };
    const tWidget = {
      name,
      disabled: false,
      title: widgetTitle,
    };

    // button widget
    if (widgetType === "BUTTON") {
      const widgetButton = { label: buttonName };
      const uiWidget = {
        name,
        disabled: false,
        button: widgetButton,
      };
      if (currentWidget) {
        uiWidget.id = currentWidget.id;
      }
      onSave([tWidget, uiWidget]);
      onClose();
      return;
    }

    // data widget
    const widgetData = { type: widgetType };
    if (widgetType === "NUMBER") {
      widgetData.min = min;
      widgetData.max = max;
      if (isInteger) {
        widgetData.step = 1;
      }
    } else if (
      widgetType === "DURATION" ||
      widgetType === "TEMPERATURE" ||
      widgetType === "TEMPERATURE_DIFF"
    ) {
      widgetData.min = min;
      widgetData.max = max;
      widgetData.unit = unit;
      widgetData.type = "NUMBER_UNIT";
      widgetData.unitType = widgetType
        .toLowerCase()
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join("");
    } else if (widgetType === "SELECT" || widgetType === "MULTI_SELECT") {
      widgetData.options = options;
    } else if (widgetType === "COLOR_HEX") {
      widgetData.type = "SPECIAL_STRING";
    } else if (
      widgetType === "COLOR_HUE" ||
      widgetType === "COLOR_TEMPERATURE"
    ) {
      widgetData.type = "SPECIAL_NUMBER";
    }

    const uiWidget = {
      name,
      disabled: readOnly,
      data: widgetData,
    };
    if (currentWidget) {
      uiWidget.id = currentWidget.id;
    }
    onSave([tWidget, uiWidget]);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {currentWidget ? "Update Widget Setting" : "New Widget"}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="Name"
          label="Name"
          type="text"
          fullWidth
          value={name}
          onChange={handleNameChange}
          error={nameError}
          helperText={nameError && "Name cannot be empty, or duplicate"}
        />
        {!currentWidget && (
          <FormControl fullWidth margin="dense">
            <InputLabel id="widget-type-label">Widget Type</InputLabel>
            <Select
              labelId="widget-type-label"
              id="widgetType"
              value={widgetType}
              onChange={handleWidgetTypeChange}
              error={widgetTypeError}
              label="Widget Type"
            >
              <MenuItem value="TOGGLE">Toggle</MenuItem>
              <MenuItem value="NUMBER">Number</MenuItem>
              <MenuItem value="BUTTON">Button</MenuItem>
              <MenuItem value="SELECT">Select</MenuItem>
              <MenuItem value="MULTI_SELECT">Multi-Select</MenuItem>
              <MenuItem value="TEXT">Text</MenuItem>
              <MenuItem value="DATE">Date</MenuItem>
              <MenuItem value="TIME">Time</MenuItem>
              <MenuItem value="TIMESTAMP">Date Time</MenuItem>
              <MenuItem value="DURATION">Time Duration</MenuItem>
              <MenuItem value="TEMPERATURE">Temperature</MenuItem>
              <MenuItem value="TEMPERATURE_DIFF">
                Temperature Difference
              </MenuItem>
              {/*<MenuItem value="MARKDOWN">Markdown</MenuItem>*/}
              {/*<MenuItem value="COLOR_HEX">Color Picker</MenuItem>*/}
              {/*<MenuItem value="COLOR_HUE">Color Hue</MenuItem>*/}
              {/*<MenuItem value="COLOR_TEMPERATURE">Color Temperature</MenuItem>*/}
            </Select>
          </FormControl>
        )}
        {/*<FormControlLabel*/}
        {/*  control={*/}
        {/*    <Checkbox*/}
        {/*      checked={readOnly}*/}
        {/*      onChange={handleReadOnlyChange}*/}
        {/*      name="readOnly"*/}
        {/*      color="primary"*/}
        {/*    />*/}
        {/*  }*/}
        {/*  label="Read-only"*/}
        {/*/>*/}
        {(widgetType === "SELECT" || widgetType === "MULTI_SELECT") && (
          <FormControl fullWidth margin="dense">
            <InputLabel shrink id="options-label">
              Options
            </InputLabel>
            <Paper
              elevation={0}
              component="ul"
              style={{
                display: "flex",
                flexWrap: "wrap",
                listStyle: "none",
                padding: "8px",
                marginTop: "8px",
                backgroundColor: "transparent",
                boxShadow: "none",
              }}
            >
              {options.map((option, index) => (
                <li key={index} style={{ margin: "4px" }}>
                  <Chip
                    label={option}
                    onDelete={handleDeleteOption(option)}
                    style={{
                      color: theme.palette.text.l1,
                      backgroundColor: theme.palette.background.l1,
                    }}
                  />
                </li>
              ))}
            </Paper>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                id="optionInput"
                label="Add Option"
                type="text"
                value={optionInput}
                onChange={handleOptionInputChange}
                onKeyDown={handleOptionInputKeyDown}
                fullWidth
                margin="dense"
              />
              <IconButton
                onClick={addOption}
                color="primary"
                aria-label="add option"
              >
                <AddIcon />
              </IconButton>
            </div>
          </FormControl>
        )}
        {widgetType === "BUTTON" && (
          <TextField
            margin="dense"
            id="buttonName"
            label="Button Label"
            type="text"
            fullWidth
            value={buttonName}
            onChange={handleButtonNameChange}
          />
        )}
        {(widgetType === "NUMBER" ||
          widgetType === "DURATION" ||
          widgetType === "TEMPERATURE" ||
          widgetType === "TEMPERATURE_DIFF") && (
          <>
            <TextField
              margin="dense"
              id="min"
              label="Min"
              type="number"
              fullWidth
              value={min}
              onChange={handleMinChange}
            />
            <TextField
              margin="dense"
              id="max"
              label="Max"
              type="number"
              fullWidth
              value={max}
              onChange={handleMaxChange}
            />
            {widgetType === "NUMBER" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isInteger}
                    onChange={handleIsIntegerChange}
                    name="integer"
                    color="primary"
                  />
                }
                label="Integer"
              />
            )}
            {(widgetType === "DURATION" ||
              widgetType === "TEMPERATURE" ||
              widgetType === "TEMPERATURE_DIFF") && (
              <FormControl fullWidth margin="dense">
                <InputLabel id="unit-label">Unit</InputLabel>
                <Select
                  labelId="unit-label"
                  id="unit"
                  value={unit}
                  onChange={handleUnitChange}
                  error={unitError}
                  label="Unit"
                >
                  {widgetType === "DURATION" && (
                    <MenuItem value="min">min</MenuItem>
                  )}
                  {widgetType === "DURATION" && (
                    <MenuItem value="sec">sec</MenuItem>
                  )}
                  {widgetType === "DURATION" && (
                    <MenuItem value="hour">hour</MenuItem>
                  )}
                  {widgetType === "DURATION" && (
                    <MenuItem value="day">day</MenuItem>
                  )}
                  {widgetType === "DURATION" && (
                    <MenuItem value="ms">ms</MenuItem>
                  )}
                  {(widgetType === "TEMPERATURE" ||
                    widgetType === "TEMPERATURE_DIFF") && (
                    <MenuItem value="C">C</MenuItem>
                  )}
                  {(widgetType === "TEMPERATURE" ||
                    widgetType === "TEMPERATURE_DIFF") && (
                    <MenuItem value="F">F</MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          {currentWidget ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpsertWidgetDialog;
